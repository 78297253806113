import React, { Component } from 'react';
import './MainPage.css';

class MainPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            landingString: "",
            strings: ["Hi! My name is Aviral Agarwal", "I am a Fullstack and ML Software Engineer", "I am a student at the University of Maryland", "Scroll Down to See my Projects"],
            stringNum: 0,
            num: 1,
            add: true
        }
        this.editString = this.editString.bind(this);
    }

    editString = () => {
        if (this.state.landingString.length === 0 && this.state.add === false) {
            if (this.state.stringNum >= this.state.strings.length - 1) {
                this.setState({
                    stringNum: 0,
                    add: !this.state.add
                });
                setTimeout(this.editString, 1500);
                return;
            } else {
                this.setState({
                    add: !this.state.add,
                    stringNum: this.state.stringNum + 1
                });
                setTimeout(this.editString, 200);
                return;
            }
        }
        if (this.state.add === true) {
            this.setState({
                landingString: this.state.strings[this.state.stringNum].substring(0, this.state.num),
                num: this.state.num + 1
            })
        } else {
            this.setState({
                landingString: this.state.strings[this.state.stringNum].substring(0, this.state.num),
                num: this.state.num - 1
            })
        }
        if (this.state.add === true && this.state.strings[this.state.stringNum].length === this.state.landingString.length) {
            this.setState({
                add: !this.state.add
            });
            setTimeout(this.editString, 1500);
            return;
        }
        setTimeout(this.editString, 50);
    }

    componentWillMount() {
        setTimeout(this.editString, 500);
    }

    render() {
        return (
            <article>
                <div className='mainbox'>
                    <div className="landingtext">
                        <p>{this.state.landingString + "|"}</p>
                    </div>
                    <div id="arrowParent">
                        <i />
                    </div>
                </div>
                <div className='second'>
                    <div className="divider">
                    </div>
                    <div id="topProj">
                        <p className="projTitle">{"Neural Style Transfer"}</p>
                        <p className="txt">
                            {"Implemented Neural Style Transfer using gradient descent with custom training loops and the VGG19 neural network to compose an image in the style of another image"}
                        </p>
                    </div>
                    <div id="middleProj">
                        <p className="projTitle">{"Reinforcement Learning"}</p>
                        <p className="txt">{"Implemented an agent that plays Atari Space Invaders using the Deep Q Learning algorithm"}</p>
                        <p className="txt">{"Implemented an agent that plays Doom Deathmatch using Policy Gradients"}</p>
                    </div>
                    <div id="bottomProj">
                        <p className="projTitle">{"Generative Adversarial Learning"}</p>
                        <p className="txt">{"Designed and Implemented a Deep Convolutional GAN (DCGAN) that generated new faces of anime characters"}</p>
                        <p className="txt">{"Implemented a StackGAN using the CUB-200 Image dataset"}</p>
                    </div>
                </div>
            </article>
        )
    }
}

//REALLY COOL PARALLAX PAGE
/*
class MainPage extends Component {
    scroll = to => {
        console.log(to);
        this.refs.parallax.scrollTo(to);
    }
    render() {
      return (
        <Parallax className="container" ref="parallax" pages={4} horizontal scrolling={true}>
          <Page offset={0} gradient="teal" caption="Aviral Agarwal" first="About Me" second="Resume" onClick={() => this.scroll(1)} />
          <Page offset={1} gradient="pink" caption="Projects" first="Technical" second="Curiosity" onClick={() => this.scroll(2)} />
          <Page offset={2} gradient="tomato" caption="Social Media" first="Linkedin" second="Medium" onClick={() => this.scroll(3)} />
          <Page offset={3} gradient="green" caption="Contact" first="Phone Number" second="Email" onClick={() => this.scroll(0)} />
        </Parallax>
      )
    }
}

const Page = ({ offset, caption, first, second, gradient, onClick }) => (
    <React.Fragment>
        <ParallaxLayer offset={offset} speed={0.2} onClick={onClick}>
            <div className="slopeBegin" />
        </ParallaxLayer>

        <ParallaxLayer offset={offset} speed={-0.2} onClick={onClick}>
            <div className={`slopeEnd ${gradient}`} />
        </ParallaxLayer>

        <ParallaxLayer className="text number" offset={offset} speed={0.3}>
            <span>0{offset + 1}</span>
        </ParallaxLayer>
        
        <ParallaxLayer className="text header" offset={offset} speed={0.4}>
            <span>
                <p style={{ fontSize: 50 }}>{caption}</p>
                <div className={`stripe ${gradient}`} />
                <p>{first}</p>
                
                <p>{second}</p>
            </span>
        </ParallaxLayer>
    </React.Fragment>
)
 */
export default MainPage;