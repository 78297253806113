import React, { Component } from 'react';
import MainPage from './MainPage';
import './App.css';

class App extends Component {
  render() {
    return (
      <MainPage/>
    );
  }
}

export default App;
